import { makeAutoObservable, runInAction } from 'mobx';
import OrderService from '../services/OrderService';
import { StoreState } from '../types/Common';
import { IEvent } from '../types/Event';
import { IPurchase } from '../types/Order';
import { GetReportDTO } from './CartStore';
import ToastStore from './ToastStore';

interface IReportStore {
  state: StoreState;
  isLoading: boolean;
  reportEvents?: IEvent[];
  reportCount?: number;
  report?: IPurchase[];
  formValues: any;
  setQueryParams: (params: any) => void;
  setFormValues: (formValues: any) => void;
  getReport: (params: GetReportDTO) => void;
}

export class ReportStore implements IReportStore {
  formValues: any = {};

  state: StoreState = 'Idle';

  report: IReportStore['report'] = undefined;

  reportCount: IReportStore['reportCount'] = 0;

  reportEvents: IReportStore['reportEvents'] = undefined;

  queryParams: any = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  setFormValues = (formValues: any) => {
    this.formValues = formValues;
  };

  setQueryParams = (params: any) => {
    this.queryParams = params;
  };

  getReport = async (params?: GetReportDTO) => {
    runInAction(() => {
      this.state = 'Loading';
    });
    try {
      const res = await OrderService.getReport(params);

      runInAction(() => {
        this.report = res.data[0];
        this.queryParams = params;
        this.reportCount = res.data[1];
        this.state = 'Success';
      });
      return res;
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.reports.getReports');
        this.state = 'Error';
      });
      throw error;
    }
  };
}
