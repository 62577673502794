import { makeAutoObservable, runInAction } from 'mobx';
import OwnerService from '../services/OwnerService';
import { StoreState } from '../types/Common';
import ToastStore from './ToastStore';

interface MyCompanyData {
  id: string;
  name: string;
  invoicingEnabled: boolean;
  invoicingFee: number;
  invoicingEmail: string;
  ownerCustomers?: { slug: string }[];
}

export class SettingsStore {
  state: StoreState = 'Idle';

  myCompanyData: MyCompanyData | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  reset = () => {
    runInAction(() => {
      this.state = 'Idle';
      this.myCompanyData = undefined;
    });
  };

  /**
   * Loads settings
   */
  getMyCompanyData = async (isLoggedIn: boolean) => {
    if (!isLoggedIn) {
      return;
    }

    this.state = 'Loading';
    try {
      const res = (await OwnerService.getMyCompanyData()) as any;
      runInAction(() => {
        this.myCompanyData = res.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.settings.getMyCompanyDataFailed');
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Loads settings
   */
  updateMyCompanyData = async (data: any) => {
    this.state = 'Loading';
    try {
      const res = (await OwnerService.updateMyCompanyData(data)) as any;
      runInAction(() => {
        this.myCompanyData = res.data;
        this.state = 'Success';
        ToastStore.showSuccess('successes.settings.updateSuccess');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.common.save');
        this.state = 'Error';
      });
      throw error;
    }
  };
}
