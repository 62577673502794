import { FC, LazyExoticComponent, ReactNode } from 'react';
import { SortingRule } from 'react-table';
import { UserRole } from './User';

export interface IRoute {
  menuGroup?: string;
  name: string;
  href: string;
  component: LazyExoticComponent<FC<any>>;
  iconComponent?: ReactNode;
  menuItem?: boolean; // is route shown in sidemenu
  nonAuth?: boolean; // is route accessible to non authenticated user
  roles?: UserRole[]; // does route require some role
  exact?: boolean;
}

export type StoreState = 'Idle' | 'Loading' | 'Error' | 'Success';

export interface IToast {
  msg: string;
  values?: any;
  type: 'error' | 'success' | 'info';
  id: string;
}

export type LivexDocumentType =
  | 'skyboxAdminServiceInformation'
  | 'skyboxAdminUserAgreement'
  | 'skyboxAdminPrivacyPolicy';

export enum AssetType {
  BINARY = 'BINARY',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export interface IAsset {
  id: string;
  name: string;
  type: AssetType;
  mimeType: string;
  width: number;
  height: number;
  source: string;
  preview: string;
}

export interface PaginationDTO {
  skip?: number;
  take?: number;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortBy extends SortingRule<{}> {}

export interface GetQueryParams extends SortBy, PaginationDTO {}

export interface IPersonalizationFields {
  None?: boolean;
  Salutation?: boolean;
  Title?: boolean;
  Name?: boolean;
  Firstname?: boolean;
  Street?: boolean;
  ZipCode?: boolean;
  City?: boolean;
  Country?: boolean;
  Telephone?: boolean;
  DateOfBirth?: boolean;
  IDNumber?: boolean;
  Nationality?: boolean;
  POBoxZIP?: boolean;
  POBoxCity?: boolean;
  Email?: boolean;
  Mobile?: boolean;
  FAX?: boolean;
  Sex?: boolean;
  StateAbbreviation?: boolean;
  CPF_Brazil?: boolean;
}

export type EventTabType =
  | 'overview'
  | 'servings'
  | 'hotels'
  | 'products'
  | 'settings';

export interface GroupedArrayItem {
  title: string;
  id: string;
  quantity: number;
  children: any[];
}
