import * as Sentry from '@sentry/react';
import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import AuthService from '../services/AuthService';
import { StoreState } from '../types/Common';
import {
  IAdminUser,
  IGetResetPassword,
  ILoginCredentials,
  IResetPassword,
  UserRole,
} from '../types/User';
import { setAuthToken } from '../utils/auth';
import ToastStore from './ToastStore';

let logoutInProgress = false;

const handleLoginError = (error: any) => {
  let errorMsg = 'errors.user.loginFailed';
  if (error.response) {
    const {
      response: {
        config: { url },
      },
    } = error;

    if (url.includes('claimShoppingCart')) {
      errorMsg = 'errors.cart.claimCartFailed';
    }
  }
  ToastStore.showError(errorMsg);
};

const ADMIN_ROLES = [
  'SuperAdmin',
  'SkyboxAdmin',
  'SuperManager',
  'Manager',
  'Admin',
] as UserRole[];

const OWNER_ROLES = ['SkyboxOwner'] as UserRole[];

const getUserRoles = (user: IAdminUser, superOwnerRole: UserRole) => {
  const roles = [...user.roles];

  if (roles.includes('SuperAdmin') || roles.includes('SuperManager')) {
    roles.push('SkyboxAdmin');
  }

  if (roles.includes('SuperOwner')) {
    roles.push(superOwnerRole);
  }

  return roles;
};

const SUPER_OWNER_ROLE_KEY = 'skybox-superowner-role';

export class AuthStore {
  state: StoreState = 'Idle';
  isAuthLoading: boolean = true;
  private _currentUser: IAdminUser | undefined = undefined;
  isInitialized = false;
  passwordChanged = false;
  superOwnerRole: UserRole = 'SkyboxOwner';

  constructor() {
    this.superOwnerRole =
      (localStorage.getItem(SUPER_OWNER_ROLE_KEY) as UserRole) || 'SkyboxOwner';
    makeAutoObservable(this);
  }

  updateSuperOwnerRole = (role: UserRole) => {
    localStorage.setItem(SUPER_OWNER_ROLE_KEY, role);
    this.superOwnerRole = role;
  };

  get currentUser() {
    return this._currentUser;
  }

  set currentUser(user: IAdminUser | undefined) {
    Sentry.setUser(user ? { email: user?.email, id: user?.id } : null);
    this._currentUser = user;
    if (this._currentUser) {
      const roles = getUserRoles(this._currentUser, this.superOwnerRole);
      this._currentUser.roles = roles;
    }
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  get isAdmin() {
    if (!this.currentUser) {
      return false;
    }
    const roles = [...ADMIN_ROLES];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isOwner() {
    if (!this.currentUser) {
      return false;
    }
    const roles = [...OWNER_ROLES];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isManager() {
    if (!this.currentUser) {
      return false;
    }
    const roles = ['Manager'];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isJanitor() {
    if (!this.currentUser) {
      return false;
    }
    const roles = ['Janitor'];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isSuperOwner() {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser?.roles.includes('SuperOwner');
  }

  get isSuperAdmin() {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser?.roles.includes('SuperAdmin');
  }

  get isLoggedIn() {
    return !!this.currentUser;
  }

  get currentUserRoles() {
    if (!this.currentUser) {
      return [];
    }

    return this.currentUser.roles;
  }

  /**
   * Logs the user in
   */
  login = async (
    credentials: ILoginCredentials,
    { onSuccess }: { onSuccess: () => Promise<void> },
  ) => {
    this.state = 'Loading';
    this.isAuthLoading = true;
    try {
      const response = await AuthService.login(credentials);

      setAuthToken(response.data.authToken);
      await onSuccess();

      runInAction(() => {
        this.state = 'Success';
        this.currentUser = response.data.user;
        this.isAuthLoading = false;
      });
      ToastStore.showSuccess('successes.user.loginSuccess', {
        email: credentials.email,
      });
    } catch (error) {
      handleLoginError(error);
      runInAction(() => {
        this.state = 'Error';
        this.isAuthLoading = false;
      });
    }
  };

  /**
   * Logs the user out (i.e. deletes the token)
   */
  logout = async () => {
    if (logoutInProgress) {
      return;
    }
    logoutInProgress = true;
    try {
      await AuthService.logout();
    } finally {
      localStorage.clear();
      window.location.reload();
    }
  };

  /**
   * Returns the current user
   */
  getCurrentUser = async () => {
    this.state = 'Loading';
    try {
      const response = await AuthService.getMe();
      runInAction(() => {
        this.currentUser = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      ToastStore.showError('errors.user.getCurrentUserFailed');
      throw error;
    }
  };

  /**
   * Refreshes current user if authToken is present
   */
  refreshIsAuthenticated = async (
    isAuthenticated: boolean,
    { onSuccess }: { onSuccess: () => Promise<void> },
  ) => {
    if (isAuthenticated) {
      this.isAuthLoading = true;
      await this.getCurrentUser();
      await onSuccess();
    } else {
      this.currentUser = undefined;
    }
    runInAction(() => {
      this.isInitialized = true;
      this.isAuthLoading = false;
    });
  };

  /**
   * Triggers password reset email to be sent into specified email address
   */
  getResetPasswordEmail = async (data: IGetResetPassword) => {
    this.state = 'Loading';
    try {
      await AuthService.getResetPasswordEmail(data);
      runInAction(() => {
        this.state = 'Success';
      });
      ToastStore.showSuccess('successes.user.passwordResetInstructionsSent');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'User not found'
        ) {
          ToastStore.showError('errors.user.resetPasswordUserNotFound');
        }
      }
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Resets current password
   */
  resetPassword = async (data: IResetPassword, onSuccess?: () => void) => {
    this.state = 'Loading';
    try {
      await AuthService.resetPassword(data);
      runInAction(() => {
        this.state = 'Success';
        this.passwordChanged = true;
      });
      ToastStore.showSuccess('successes.user.passwordReset');
      onSuccess?.();
    } catch (error) {
      let msg = 'errors.user.passwordResetFailed';

      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === '"Expired or bad token'
        ) {
          msg = 'errors.user.passwordResetFailedExpired';
        }
      }
      runInAction(() => {
        this.state = 'Error';
      });

      ToastStore.showError(msg);
      throw error;
    }
  };
}
