import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ExportToCsv } from 'export-to-csv';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PATHS } from '../../../constants/routes';
import {
  getAdminReportColumns,
  getOwnerReportColumns,
} from '../../../screens/Reports/ReportColumns';
import { useStores } from '../../../stores/context/useStores';
import { theme } from '../../../styles';
import colors from '../../../styles/colors';
import { Layout } from '../Layout';

export interface ReportsLayoutProps {}

const useStyles = makeStyles({
  criteriaLabel: {
    color: colors.text.body2,
    marginRight: theme.spacing(1),
  },
  button: {
    height: 40,
  },
});

export const ReportsLayout: FC<React.PropsWithChildren<ReportsLayoutProps>> =
  observer(({ children }) => {
    const { t } = useTranslation();
    const {
      reportStore: { queryParams, report },
      authStore: { isOwner },
    } = useStores();
    const classes = useStyles();
    const navigate = useNavigate();

    const prettifyDate = (date: string, forFileName?: boolean) => {
      const format = forFileName ? 'YYYYMMDD' : 'DD.MM.YYYY';
      return moment(date).format(format);
    };

    const getPrettyPeriod = (forFileName?: boolean) => {
      if (!queryParams) return '';
      const str = `${prettifyDate(
        queryParams.startDate,
        forFileName,
      )} - ${prettifyDate(queryParams.endDate, forFileName)}`;
      return str;
    };

    const getDateRange = () => {
      if (queryParams.dateRange === 'period') return getPrettyPeriod();
      return t(`reports.dateRanges.${queryParams.dateRange}`);
    };

    const getEventName = () => {
      if (queryParams.eventQueryType === 'all')
        return t('reports.eventQueryTypes.allEvents');
      if (queryParams.eventTitle) return queryParams.eventTitle;
      return '-';
    };

    const getSeriesName = () => {
      if (queryParams.eventQueryType === 'all')
        return t('reports.eventQueryTypes.all');
      if (queryParams.eventSeriesTitle) return queryParams.eventSeriesTitle;
      return '-';
    };

    const getSkyboxName = () => {
      if (queryParams.skyboxQueryType === 'all')
        return t('reports.skyboxQueryTypes.all');
      if (queryParams.skyboxName) return queryParams.skyboxName;
      return '-';
    };

    const SEARCH_CRITERIA_FIELDS = queryParams && [
      { label: 'reports.list.dateRange', value: getDateRange() },
      { label: 'common.eventSeries', value: getSeriesName() },
      { label: 'common.event', value: getEventName() },
      { label: 'common.skybox', value: getSkyboxName() },
    ];

    const getCsvFilename = () => {
      const base = `sales-report-`;
      let eventStr = '';
      if (queryParams?.eventId) eventStr = `event-${queryParams.eventId}`;
      return `${base}${eventStr}${getPrettyPeriod(true)}`;
    };

    const handleCsvExport = () => {
      const COLUMNS = isOwner
        ? getOwnerReportColumns(t)
        : getAdminReportColumns(t);
      const headers = COLUMNS.map((column) => column.Header);

      const options = {
        useKeysAsHeaders: false,
        showLabels: true,
        headers,
        filename: getCsvFilename(),
      };

      const csvRows = report?.map((reportRow: any) => {
        return COLUMNS.map((column: any) => {
          // the react-table Cell format
          const data = { row: { original: reportRow } };

          if (column.PrintCell) {
            return column.PrintCell(data);
          }
          if (column.Cell) {
            return column.Cell(data);
          }
          return reportRow[column.accessor];
        });
      });
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(csvRows);
    };

    const onCreateNewClick = () => {
      navigate(PATHS.skyboxReports.main);
    };

    const renderHeaderRight = () => {
      const controls = [
        {
          onClick: onCreateNewClick,
          icon: faPlus,
          label: 'reports.createNew',
        },
        {
          onClick: handleCsvExport,
          label: 'reports.saveCsv',
          icon: faFileExport,
        },
      ];

      return (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          <Grid container>
            {queryParams &&
              SEARCH_CRITERIA_FIELDS.map(
                (field: { label: string; value: string }) => (
                  <Grid item key={field.label} md={6}>
                    <Grid container>
                      <Grid md={5} item>
                        <Typography className={classes.criteriaLabel}>
                          {t(field.label)}:
                        </Typography>
                      </Grid>
                      <Grid md={7} item>
                        <Typography>{field.value}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ),
              )}
          </Grid>
          <Box ml={3} display="flex" minWidth={300}>
            {controls.map(({ onClick, label, icon }) => (
              <Box ml={1}>
                <Button onClick={onClick} variant="outlined">
                  <FontAwesomeIcon icon={icon} />
                  <Box ml={1} component="span">
                    <Typography>{t(label)}</Typography>
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      );
    };
    return (
      <Layout headerText={t('sidemenu.reports')}>
        {renderHeaderRight()}
        {children}
      </Layout>
    );
  });
