import { Box, BoxProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { darken } from 'polished';
import { FC } from 'react';
import { theme } from '../../styles';
import colors from '../../styles/colors';

export type ColorBoxColor = 'green' | 'red' | 'grey' | 'yellow';
export interface ColorBoxProps extends BoxProps {
  color: ColorBoxColor;
  whiteText?: boolean;
  p?: number;
}

export const ColorBox: FC<ColorBoxProps> = ({
  color,
  whiteText,
  children,
  p,
  ...rest
}) => {
  const getColor = () => {
    if (whiteText) return colors.text.inverted;
    if (color === 'green') return darken(0.1, colors.green);
    if (color === 'red') return darken(0.1, colors.red);
    if (color === 'yellow') return darken(0.1, colors.gold7);
    return colors.text.body1;
  };
  const getBorderColor = () => {
    if (color === 'green') return darken(0.1, colors.green);
    if (color === 'red') return darken(0.1, colors.red);
    if (color === 'yellow') return darken(0.1, colors.gold7);
    return colors.stroke;
  };
  const getBackgroundColor = () => {
    if (color === 'green') return darken(0.2, colors.green);
    if (color === 'red') return darken(0.1, colors.red);
    if (color === 'yellow') return darken(0.4, colors.gold7);
    return colors.background.bg2;
  };

  const useStyles = makeStyles({
    box: {
      fontStyle: 'normal',
      color: getColor(),
      borderColor: getBorderColor(),
      border: '1px solid',
      padding: theme.spacing(p || 1),
      backgroundColor: getBackgroundColor(),
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.box} {...rest}>
      {children}
    </Box>
  );
};
