import i18n from 'i18next';
import moment from 'moment-timezone';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_TIME_ZONE } from '../utils';
import en from './locales/en.json';
import fi from './locales/fi.json';

moment.locale('fi');
moment.localeData('fi');
moment.tz.setDefault(DEFAULT_TIME_ZONE);

export const languages = [{ name: 'Suomi', value: 'fi' }];

export const initLang = async (lng: string) => {
  await i18n.use(initReactI18next).init({
    fallbackLng: languages[0]!.value,
    lng,
    resources: {
      fi: { translation: fi },
      en: { translation: en },
    },
    // Escaping values is only necessary for dangerouslySetInnerHTML uses in React
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
};
