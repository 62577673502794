import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableInstance,
  TableOptions,
  useFilters,
  UseFiltersState,
  useMountedLayoutEffect,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useRowSelect,
  UseRowSelectState,
  useSortBy,
  UseSortByState,
  useTable,
} from 'react-table';
import Pagination from '../Pagination/Pagination';
import FilterField from './MaterialTableFilterField';
import TableHead from './MaterialTableHead';

let prevSelectedCount = 0;
let prevSelected: Record<string, boolean> | undefined = undefined;
let prevPageSize: number | undefined = undefined;

type PaginationTableInstance<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> & {
    state: UsePaginationState<T> &
      UseFiltersState<T> &
      UseSortByState<T> &
      UseRowSelectState<T>;
  };

const useStyles = makeStyles({
  spinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  pagination: {
    backgroundColor: '#fff',
  },
  container: (props: { maxWidth?: boolean }) => ({
    position: 'relative',
    flexGrow: '1',
    width: 'unset',
    maxWidth: '100%',
    ...(props.maxWidth ? { width: '100%' } : {}),
    alignSelf: 'flex-start',
    overflowX: 'auto',
    marginBottom: 12,
  }),
  table: (props) => ({
    width: 'unset',
    maxWidth: '100%',
    ...(props.maxWidth ? { width: '100%' } : {}),
  }),
  root: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  unselectedRow: {
    '& > td:not(:first-child)': {
      opacity: 0.5,
    },
  },
});

interface MaterialTableProps {
  data?: Array<Record<string, any>>;
  columns: Array<Record<string, any>>;
  onPageChange?: (pageIndex: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onSortingChange?: (sortBy: any) => void;
  getRowProps?: (row: Record<string, any>) => Record<string, any>;
  onFilteringChange?: (filters: any) => void;
  isLoading?: boolean;
  totalCount?: number;
  defaultPage?: number;
  defaultPageSize?: number;
  paginationEnabled?: boolean;
  fixed?: boolean;
  maxWidth?: boolean;
  onRowSelect?: (selectedIds: string[]) => void;
  onRemoveLastSelection?: () => void;
  selectedIds?: string[] | null;
  disabledIds?: string[] | null;
  footerRow?: any[] | null;
  controlledPageIndex?: number | null;
  useControlledState?: boolean;
  checkboxColumnLabel?: string | null;
  checkboxAsRadio?: boolean;
  loading?: boolean;
  initialState?: any | null;
}

const MaterialTable = (props: MaterialTableProps) => {
  const {
    data = props.data ?? [],
    columns = props.columns,
    totalCount = props.totalCount ?? 0,
    onPageChange = props.onPageChange,
    onSortingChange = props.onSortingChange,
    onFilteringChange = props.onFilteringChange,
    onPageSizeChange = props.onPageSizeChange,
    isLoading = props.isLoading ?? false,
    footerRow = props.footerRow ?? null,
    defaultPage = props.defaultPage ?? 0,
    defaultPageSize = props.defaultPageSize ?? 10,
    paginationEnabled = props.paginationEnabled,
    fixed = props.fixed ?? false,
    maxWidth = props.maxWidth ?? true,
    onRowSelect = props.onRowSelect,
    checkboxColumnLabel = props.checkboxColumnLabel ?? null,
    onRemoveLastSelection = props.onRemoveLastSelection,
    selectedIds = props.selectedIds ?? null,
    disabledIds = props.disabledIds ?? null,
    controlledPageIndex = props.controlledPageIndex ?? null,
    useControlledState = props.useControlledState ?? false,
    checkboxAsRadio = props.checkboxAsRadio ?? false,
    loading = props.loading ?? false,
    initialState: initState = props.initialState ?? null,
  }: MaterialTableProps = props;
  const { t } = useTranslation();

  const getSelectedCheckboxesObj = useCallback(
    (selectedIds: string[], data: any) => {
      if (!selectedIds) {
        return;
      }
      const obj = {} as any;
      selectedIds.map((id) => {
        const dataIndex = data.findIndex((item: any) => item.id === id);
        obj[dataIndex] = true;
        return null;
      });
      return obj;
    },
    [selectedIds, data],
  );

  const getSelectedIdsFromObj = (selectedKeys: any, data: any) => {
    return Object.keys(selectedKeys)
      .map((selectedIndex) => {
        if (!data || !data[selectedIndex]) return null;
        return data[selectedIndex].id;
      })
      .filter((val) => val);
  };

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: FilterField,
    }),
    [],
  );

  const plugins = [];
  const afterPaginationPlugins = [];
  const initialState = {
    ...{
      pageIndex: defaultPage,
      pageSize: defaultPageSize,
      selectedRowIds: getSelectedCheckboxesObj(selectedIds as any, data),
    },
    ...(initState && initState),
  };

  if (onFilteringChange) {
    plugins.push(useFilters);
    initialState.filters = [];
  }

  if (onSortingChange) {
    plugins.push(useSortBy);
    if (!initState?.sortBy) {
      initialState.sortBy = [];
    }
  }

  if (onRowSelect) {
    afterPaginationPlugins.push(useRowSelect);
  }

  const classes = useStyles({ maxWidth });

  const onCheckboxClick = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    checked: boolean,
    isToggleAll: boolean,
  ) => {
    if (onRemoveLastSelection) {
      if (
        ((isToggleAll && !checked) ||
          (!isToggleAll && prevSelectedCount === 1)) &&
        !checked
      ) {
        onRemoveLastSelection();
        return false;
      }
    }
    onChange(e);
  };

  const isDisabled = (id: string) => {
    return disabledIds ? disabledIds.includes(id) : false;
  };

  const opts: TableOptions<object> = {
    data,
    columns,
    manualSortBy: !!onSortingChange,
    manualPagination: true,
    manualFilters: !!onFilteringChange,
    pageCount: totalCount,
    autoResetPage: false,
    defaultColumn,
    initialState,
  } as unknown as TableOptions<object>;

  if (useControlledState) {
    opts.useControlledState = (state: any) => {
      return useMemo(
        () => ({
          ...state,
          pageIndex: controlledPageIndex,
        }),
        [state, controlledPageIndex],
      );
    };
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
    gotoPage,
    state: { sortBy, pageIndex, pageSize, filters, selectedRowIds },
  } = useTable(
    { ...opts },
    ...plugins,
    usePagination,
    ...afterPaginationPlugins,
    (hooks) => {
      if (!onRowSelect) {
        return null;
      }
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          style: {
            maxWidth: 70,
            width: 70,
          },
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => {
            const { onChange, ...rest } = getToggleAllRowsSelectedProps();
            return (
              <Box display="flex" alignItems="center">
                {!checkboxAsRadio && (
                  <Checkbox
                    onChange={(e, checked) =>
                      onCheckboxClick(e, onChange, checked, true)
                    }
                    {...rest}
                  />
                )}
                {checkboxColumnLabel}
              </Box>
            );
          },
          Cell: ({ row }: any) => {
            const { onChange, ...rest } = row.getToggleRowSelectedProps();
            return (
              <Checkbox
                disabled={isDisabled(row.id)}
                onChange={(e, checked) =>
                  onCheckboxClick(e, onChange, checked, false)
                }
                {...rest}
              />
            );
          },
        },
        ...columns,
      ]);
    },
  ) as PaginationTableInstance<object>;

  /**
   * Resets prev page size on unmount
   */
  useEffect(() => {
    return () => {
      prevPageSize = undefined;
    };
  }, []);

  useMountedLayoutEffect(() => {
    if (JSON.stringify(prevSelected) === JSON.stringify(selectedRowIds)) {
      return;
    }
    prevSelected = selectedRowIds;
    onRowSelect && onRowSelect(getSelectedIdsFromObj(selectedRowIds, data));
  }, [onRowSelect, selectedRowIds]);

  /**
   * When sorting changes..
   */
  useEffect(() => {
    if (onSortingChange && sortBy.length) {
      onSortingChange({ sortBy });
    }
  }, [onSortingChange, sortBy]);

  /* When page size changes */
  useEffect(() => {
    if (onPageSizeChange) {
      if (prevPageSize !== undefined) {
        onPageSizeChange(pageSize);
      }
      prevPageSize = pageSize;
    }
  }, [pageSize]);

  /**
   * When page / row count changes ..
   */
  useEffect(() => {
    if (onPageChange) {
      onPageChange(pageIndex);
    }
  }, [pageIndex]);

  /**
   * When filters change ..
   */
  useEffect(() => {
    if (onFilteringChange) {
      onFilteringChange(filters);
    }
  }, [filters, onFilteringChange]);

  const getRowClassName = (row: any) => {
    if (!onRowSelect || row.isSelected) {
      return undefined;
    }
    return classes.unselectedRow;
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <TableContainer className={classes.container}>
        {isLoading && <CircularProgress style={{ position: 'absolute' }} />}
        <Table
          stickyHeader
          aria-label="sticky table"
          {...getTableProps()}
          className={classes.table}
          style={fixed ? { tableLayout: 'fixed' } : undefined}
        >
          <TableHead
            onFilteringChange={onFilteringChange}
            onSortingChange={onSortingChange}
            headerGroups={headerGroups}
          />
          <TableBody {...getTableBodyProps()} style={{ maxHeight: '50vh' }}>
            {!isLoading &&
              rows.map((row) => {
                prepareRow(row);

                return (
                  <TableRow hover key={row.id} className={getRowClassName(row)}>
                    {row.cells.map((cell) => {
                      const { key, ...props } = cell.getCellProps();
                      return (
                        <TableCell
                          key={`${cell.column.id}-${cell.row.id}`}
                          {...props}
                          style={props.style}
                          width={cell.column.width}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
          {footerRow && !!rows.length && (
            <TableFooter>
              <TableRow>
                {footerRow.map((column) => (
                  <TableCell>
                    {column.render ? column.render() : column.value || column}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {!data?.length && (
        <Box display="flex" justifyContent="center" fontWeight={600} mt={2}>
          {t('common.noResults')}
        </Box>
      )}
      {paginationEnabled ? (
        <Pagination
          totalCount={totalCount}
          page={controlledPageIndex ?? pageIndex}
          pageSize={pageSize}
          gotoPage={
            useControlledState && onPageChange ? onPageChange : gotoPage
          }
          className={classes.pagination}
          onPageSizeChange={onPageSizeChange && setPageSize}
        />
      ) : null}
    </Box>
  );
};

export default MaterialTable;
