import { Box, Breadcrumbs, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { PATHS } from '../../../constants/routes';
import { useStores } from '../../../stores/context/useStores';
import { TabType } from '../../Tabs';
import { TabbedLayout } from './TabbedLayout';

interface OrderLayoutState {
  showChildOrder?: boolean;
}

export const getOrderLayoutStateParams = (state: OrderLayoutState) => {
  return new URLSearchParams(state as Record<string, string>).toString();
};

export const showChildOrderByParams = (search: string) => {
  return new URLSearchParams(search).get('showChildOrder') === 'true';
};

interface OrderLayoutProps {
  livexOrderNumber: string;
  tabs: ('details' | 'sendTickets')[];
  showChildOrder?: boolean;
}

export const OrderLayout: FC<React.PropsWithChildren<OrderLayoutProps>> =
  observer(({ tabs, livexOrderNumber, showChildOrder, children }) => {
    const {
      authStore: { isOwner, isLoggedIn },
      orderStore: { order },
      skyboxStore: {
        skyboxOrder,
        getSkyboxOrder,
        getMySkyboxOrder,
        getSkyboxInvites,
        reset,
      },
    } = useStores();
    const { t } = useTranslation();

    useEffect(() => {
      reset();
      if (isOwner) {
        getMySkyboxOrder(livexOrderNumber);
      } else {
        getSkyboxOrder(livexOrderNumber);
      }
    }, [reset, isOwner, getSkyboxOrder, getMySkyboxOrder, livexOrderNumber]);

    useEffect(() => {
      if (skyboxOrder) {
        getSkyboxInvites({ purchaseId: skyboxOrder.purchaseId, isLoggedIn });
      }
    }, [skyboxOrder, getSkyboxInvites, isLoggedIn]);

    const tabItems: TabType<OrderLayoutState>[] = useMemo(() => {
      return tabs.map((str) => ({
        label: t(`orders.detailsModal.tab.${str}`),
        path: (isOwner ? PATHS.orders.owners : PATHS.orders.skybox)[
          str
        ].replace(':livexOrderNumber', livexOrderNumber),
        state: {
          showChildOrder,
        },
      }));
    }, [t, isOwner, livexOrderNumber, showChildOrder, tabs]);

    const Crumbs = (
      <Box mb={2}>
        <Breadcrumbs>
          <Link
            to={
              isOwner ? PATHS.orders.owners.upcoming : PATHS.orders.skybox.main
            }
          >
            <Typography>{t('sidemenu.orders')}</Typography>
          </Link>
          <Typography>{livexOrderNumber}</Typography>
        </Breadcrumbs>
      </Box>
    );

    return (
      <TabbedLayout
        title={t(
          `orders.detailsModal.${showChildOrder ? 'childTitle' : 'title'}`,
          {
            orderNumber: showChildOrder
              ? order?.livexOrderNumber
              : skyboxOrder?.purchase?.livexOrderNumber,
          },
        )}
        tabs={tabItems}
        headerElementsTop={Crumbs}
      >
        {children}
      </TabbedLayout>
    );
  });
