/* eslint-disable react-hooks/exhaustive-deps */
import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faBars,
  faCog,
  faSignOutAlt,
  faUniversity,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  BoxProps,
  darken,
  Hidden,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/context/useStores';
import colors from '../../styles/colors';
import { getUserDisplayNameOrEmail } from '../../utils/getUserDisplayName';
import { SuperOwnerSettingsModal } from '../Modals/hocs/SuperOwnerSettingsModal';

interface TopBarProps {}

interface TopBarItemProps extends BoxProps {
  icon: any;
  text: string;
  clickCallback?: any;
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.background.bg1,
    height: 60,
    boxShadow: '4px 4px 8px rgba(0, 16, 60, 0.1)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    height: 48,
  },

  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    minHeight: 24,
    backgroundColor: colors.background.bg3,
    borderRadius: 24,
  },
  itemWrapper: {
    '& > *': {
      marginRight: 24,
    },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  itemText: {
    color: theme.palette.secondary.main,
  },
  itemOverlayButton: {
    backgroundColor: 'transparent',
    border: 'none',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // Needed for Firefox
    width: '100%',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&.with-button': {
      '&:hover': {
        '& .icon': {
          backgroundColor: darken(colors.background.bg3, 0.1),
        },
      },
    },
  },
}));

export const TopBarItem: React.FC<TopBarItemProps> = ({
  icon,
  text,
  clickCallback,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.itemContainer} ${
        clickCallback ? 'with-button' : ''
      }`}
      {...rest}
    >
      {clickCallback && (
        <button className={classes.itemOverlayButton} onClick={clickCallback} />
      )}
      <Box className={`${classes.itemIcon} icon`} mr={1}>
        <FontAwesomeIcon icon={icon} />
      </Box>
      <Hidden smDown>
        <Box className={classes.itemText}>{text}</Box>
      </Hidden>
    </Box>
  );
};

const TopBar: React.FC<TopBarProps> = observer(() => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [superOwnerModalOpen, setSuperOwnerModalOpen] = useState(false);
  const {
    settingsStore: { myCompanyData, getMyCompanyData },
    authStore: {
      isOwner,
      logout,
      currentUser,
      isSuperOwner,
      isLoggedIn,
      superOwnerRole,
    },
    commonStore: {
      uiState: { mobileDrawerActive },
      toggleMobileDrawer,
    },
    customerStore: { currentCustomer },
  } = useStores();

  useEffect(() => {
    if (!myCompanyData && (isOwner || isSuperOwner)) {
      getMyCompanyData(isLoggedIn);
    }
  }, [getMyCompanyData, isLoggedIn]);

  const showMobileMenu = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const onModalClose = () => {
    setSuperOwnerModalOpen(false);
  };

  const onSuperOwnerSettingsClick = () => {
    setSuperOwnerModalOpen(true);
  };

  return (
    <Box className={`no-print ${classes.container}`}>
      <Box display="flex" alignItems="center" px={3}>
        {showMobileMenu && (
          <IconButton
            aria-label="menu"
            onClick={() => toggleMobileDrawer({ isOpen: !mobileDrawerActive })}
            size="large"
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
        )}

        <Box display="flex" sx={{ alignItems: 'center', gap: 1 }}>
          {currentCustomer?.logoAsset?.source && (
            <img
              className={classes.logo}
              src={currentCustomer.logoAsset.source}
              alt={currentCustomer.name}
            />
          )}
          <Typography variant="h4" color={'secondary'}>
            {t('common.toolName')}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" className={classes.itemWrapper}>
        {isSuperOwner && (
          <TopBarItem
            text={t('user.superOwnerModal.title')}
            icon={faCog}
            clickCallback={onSuperOwnerSettingsClick}
          />
        )}

        {((isSuperOwner && superOwnerRole === 'SkyboxOwner') || isOwner) &&
          myCompanyData && (
            <TopBarItem text={myCompanyData?.name} icon={faUniversity} />
          )}

        <TopBarItem
          text={currentUser ? getUserDisplayNameOrEmail(currentUser) : ''}
          icon={faUser}
        />

        <TopBarItem
          text={t('common.logout')}
          icon={faSignOutAlt}
          clickCallback={logout}
        />
      </Box>
      {isSuperOwner && (
        <SuperOwnerSettingsModal
          onClose={onModalClose}
          open={superOwnerModalOpen}
        />
      )}
    </Box>
  );
});

export default TopBar;
