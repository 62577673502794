import '@fontsource/inter';
import 'moment/locale/fi';
import { createRoot } from 'react-dom/client';
import App from './App';
import BaseErrorBoundary from './components/BaseErrorBoundary/BaseErrorBoundary';
import { initLang } from './i18n/index';
import { StoresProvider } from './stores/context/StoresProvider';

initLang('fi');
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BaseErrorBoundary>
    <StoresProvider>
      <App />
    </StoresProvider>
    ,
  </BaseErrorBoundary>,
);
