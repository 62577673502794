import Input from '@mui/material/Input';

const FilterField = ({
  column: { filterValue, setFilter },
}: FilterFieldProps) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

interface FilterFieldProps {
  column: {
    setFilter: (v: string) => void;
    filterValue: string;
  };
}

export default FilterField;
