import { SkeletonClassKey } from '@mui/lab';
import { createTheme } from '@mui/material';
import colors from './colors';
import { getPickerOverrides } from './picker-overrides';

declare module '@mui/material/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey;
  }
}

export const SPACING = [0, 8, 16, 24, 32, 48, 64, 128, 160, 192, 256];
export const FONT_SIZES = [
  '0.80rem',
  '0.85rem',
  '1rem',
  '1.125rem',
  '1.5rem',
  '2.25rem',
];
const DEFAULT_ACCENT = '#202867';

export const getTheme = (accentColor?: string) => {
  const primaryAccentColor = accentColor || DEFAULT_ACCENT;
  const currentColors = {
    ...colors,
    accent: { primary: primaryAccentColor },
  };

  return createTheme({
    typography: {
      fontFamily: ['Inter', 'Verdana', 'sans-serif'].join(','),
      fontSize: 14,
      body1: {
        color: currentColors.text.body1,
        fontSize: FONT_SIZES[0],
      },
      body2: {
        color: currentColors.text.body2,
        fontSize: FONT_SIZES[0],
      },
      h1: {
        fontSize: FONT_SIZES[5],
        fontWeight: 700,
        color: currentColors.accent.primary,
      },
      h2: {
        fontSize: FONT_SIZES[4],
        fontWeight: 700,
        marginBottom: SPACING[2],
        color: currentColors.accent.primary,
      },
      h3: {
        fontSize: FONT_SIZES[3],
        fontWeight: 'bold',
        color: currentColors.accent.primary,
      },
      h4: {
        fontSize: FONT_SIZES[2],
        fontWeight: 'bold',
        color: currentColors.accent.primary,
      },
      h5: {
        fontSize: FONT_SIZES[1],
        fontWeight: 'bold',
        color: currentColors.accent.primary,
      },
      button: {
        fontSize: FONT_SIZES[3],
        fontWeight: 'normal',
        textTransform: 'none',
      },
    },
    spacing: SPACING,
    palette: {
      primary: {
        main: currentColors.text.body1,
      },
      secondary: {
        main: currentColors.accent.primary,
      },
      text: {
        primary: currentColors.text.body1,
        secondary: currentColors.text.body2,
      },
      background: {
        default: currentColors.background.bg2,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'secondary',
        },
        styleOverrides: {
          root: {
            fontSize: FONT_SIZES[0],
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: FONT_SIZES[0],
          },
        },
      },
      ...getPickerOverrides(currentColors.accent.primary),
    },
  });
};

export const updateTheme = (primaryColor?: string) => {
  theme = getTheme(primaryColor);
  return theme;
};

export let theme = getTheme();

export default theme;
