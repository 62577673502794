import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  isChunkLoadError: boolean;
}

const checkIsChunkLoadError = (error: Error) => {
  return (
    /Loading chunk [\d]+ failed/.test(error.message) ||
    /text\/html.*is not a valid JavaScript MIME type/.test(error.message) ||
    /Failed to fetch dynamically imported module/.test(error.message)
  );
};

class BaseErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      isChunkLoadError: false,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      isChunkLoadError: checkIsChunkLoadError(error),
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!checkIsChunkLoadError(error)) {
      Sentry.captureException(error, { data: errorInfo });
    }
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  componentDidUpdate(_: ErrorBoundaryProps, prevState: ErrorBoundaryState) {
    if (
      this.state.hasError &&
      this.state.isChunkLoadError &&
      !prevState.hasError
    ) {
      setTimeout(() => {
        window.location.assign('/');
      }, 150);
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.isChunkLoadError) {
        return (
          <h1
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              textAlign: 'center',
              color: '#333',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            Application has been updated, reloading application...
          </h1>
        );
      } else {
        return (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              color: '#333',
              fontFamily: 'Arial, sans-serif',
              padding: '1rem',
            }}
          >
            <h1>Something went wrong.</h1>
            <p>Please reload the application.</p>
            <button
              onClick={() => window.location.assign('/')}
              style={{
                padding: '0.5rem 1rem',
                fontSize: '1rem',
                cursor: 'pointer',
              }}
            >
              Reload
            </button>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default BaseErrorBoundary;
