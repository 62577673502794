import { getInitializedStores } from './Stores';
import { StoresContext } from './StoresContext';

export const StoresProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const stores = getInitializedStores();

  return (
    <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
  );
};
