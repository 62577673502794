import { ShoppingCart } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { BOOKING_SIDEBAR_STEPS, PATHS } from '../../../constants/routes';
import { useStores } from '../../../stores/context/useStores';
import colors from '../../../styles/colors';
import { getCartQuantity } from '../../../utils/cartUtils';
import { getPrettyDate, getPrettySum } from '../../../utils/i18nUtils';
import { ExpiryTimer } from '../../ExpiryTimer/ExpiryTimer';
import { FormHeader } from '../../FormHeader';
import { CommonModal } from '../../Modals/CommonModal';
import { CartModal } from '../../Modals/hocs/CartModal';
import TopBar from '../TopBar';

export interface AddOrderLayoutProps {
  confirmDisabled?: boolean;
  title: string | ReactNode;
  onConfirm: () => void;
  hideControls?: boolean;
  hideCart?: boolean;
  hideConfirm?: boolean;
  hideTimer?: boolean;
}

const CONTROL_BUTTON_HEIGHT = 60;

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: colors.background.bg1,
    boxShadow: '1px 1px 6px 5px rgba(0,0,0,0.02)',
  },
  contentContainer: {
    borderLeft: '1px solid',
    borderColor: colors.stroke,
    flex: '1 1 100%',
  },
  aside: {
    minWidth: 300,
    backgroundColor: theme.palette.secondary.main,
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
  },
  confirm: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
    minHeight: CONTROL_BUTTON_HEIGHT,
  },
  cancel: {
    minHeight: CONTROL_BUTTON_HEIGHT,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background.bg1,
    borderBottom: `1px solid ${colors.stroke}`,
  },
  headerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    '& h1': {
      color: theme.palette.secondary.main,
    },
  },
  headerLabel: {
    marginRight: theme.spacing(1),
  },
  cartButton: {
    minWidth: 170,
    minHeight: CONTROL_BUTTON_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    '.MuiButton-label': {
      justifyContent: 'center',
      width: '100%',
    },
  },
  cartButtonSum: {
    fontWeight: 600,
  },
  cartButtonQuantity: {},
  layout: {
    paddingTop: 60,
    minHeight: '100vh',
    flexDirection: 'column',
    display: 'flex',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: 500,
  },
}));

type SidebarStep = {
  label: string;
  href: string;
};

export const AddOrderLayout: FC<React.PropsWithChildren<AddOrderLayoutProps>> =
  observer(
    ({
      children,
      confirmDisabled,
      onConfirm,
      title,
      hideControls,
      hideCart,
      hideConfirm,
      hideTimer,
    }) => {
      const { t } = useTranslation();
      const classes = useStyles();
      const [cancelModalOpen, setCancelModalOpen] = useState(false);
      const [cartModalOpen, setCartModalOpen] = useState(false);
      const {
        eventStore: { event, getEvent },
        cartStore: {
          activeStep,
          cart,
          getCart,
          isChildOrder,
          clearCart,
          savedCartId,
          sum,
        },
      } = useStores();

      const navigate = useNavigate();

      const availableSteps = useMemo(() => {
        // If we don't have a cart, an event or event doesn't match the event in cart
        if (!cart || !event || event.id !== cart?.eventId) {
          return BOOKING_SIDEBAR_STEPS;
        }

        if (isChildOrder) {
          return BOOKING_SIDEBAR_STEPS.filter((step) => step.id !== 'customer');
        }

        return BOOKING_SIDEBAR_STEPS;
      }, [event, cart, isChildOrder]);

      const navigateToMain = useCallback(() => {
        if (isChildOrder) {
          navigate(
            cart?.parent?.livexOrderNumber
              ? PATHS.orders.owners.details.replace(
                  ':livexOrderNumber',
                  String(cart.parent.livexOrderNumber),
                )
              : PATHS.orders.owners.upcoming,
          );
        } else {
          navigate(
            event?.id && event.eventSeriesId
              ? PATHS.skyboxEvents.eventSkyboxes
                  .replace(':eventId', String(event.id))
                  .replace(':eventSeriesId', String(event.eventSeriesId))
              : PATHS.skyboxEvents.allEvents,
          );
        }
      }, [isChildOrder, history, event, cart?.parent?.livexOrderNumber]);

      useEffect(() => {
        if (!cart && savedCartId) {
          getCart();
        }
      }, [cart, savedCartId, getCart]);

      useEffect(() => {
        if (cart) {
          if (!event || !(event.id === cart.eventId)) {
            getEvent(cart.eventSeriesId, cart.eventId, true);
          }
        }
      }, [cart, event, getEvent]);

      useEffect(() => {
        if (event || activeStep === 0) {
          navigate(availableSteps[activeStep]!.href);
        }
      }, [activeStep, history, availableSteps, event]);

      const navigateAway = () => {
        setCancelModalOpen(false);
        navigateToMain();
      };

      const onCancelConfirm = async () => {
        try {
          await clearCart();
        } finally {
          navigateAway();
        }
      };

      const onCancelClose = () => {
        setCancelModalOpen(false);
      };

      const onCancelClick = () => {
        if (cart) {
          setCancelModalOpen(true);
          return;
        }
        navigateAway();
      };

      const renderLabel = (step: SidebarStep) => {
        return t(step.label);
      };

      const onCartModalClose = () => {
        setCartModalOpen(false);
      };

      const renderEventTitle = () => {
        if (!event || cart?.eventId !== event?.id) return '';
        return (
          <Typography>
            {event.title}{' '}
            {getPrettyDate(event.date, true, t('common.clockAbbr'))}
          </Typography>
        );
      };

      const CartButton = (
        <Button
          variant="outlined"
          className={classes.cartButton}
          onClick={() => setCartModalOpen(true)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ShoppingCart />
            <Box ml={2} textAlign="left">
              <Typography className={classes.cartButtonSum}>
                {getPrettySum(sum, true)}
              </Typography>
              <Typography className={classes.cartButtonQuantity}>
                {t('common.productCount', { count: getCartQuantity(cart!) })}
              </Typography>
            </Box>
          </Box>
        </Button>
      );

      const Header = (
        <Box className={classes.header} component="header">
          <Box className={classes.headerContent} py={4} px={3}>
            <Box flex="2 1 100%">
              <Typography variant="h1">{t('orders.add.newOrder')}</Typography>
              {renderEventTitle() && !!cart?.purchaseSkyboxes?.length && (
                <Box mt={2} width="100%">
                  <Grid container>
                    <Grid xs={1} item>
                      <Typography className={classes.headerLabel}>
                        Tilaisuus
                      </Typography>
                    </Grid>
                    <Grid xs={11} item>
                      {renderEventTitle()}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid xs={1} item>
                      <Typography className={classes.headerLabel}>
                        Aitio
                      </Typography>
                    </Grid>
                    <Grid xs={11} item>
                      {
                        <Typography>
                          {cart.purchaseSkyboxes[0]!.skyboxName}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
            {!hideControls && (
              <Box className={classes.controls}>
                {!hideCart && <Box mr={2}>{CartButton}</Box>}
                <Box>
                  {!hideConfirm && (
                    <Button
                      onClick={onConfirm}
                      disabled={confirmDisabled}
                      className={classes.confirm}
                    >
                      {t('orders.add.event.confirmAndContinue')}
                    </Button>
                  )}
                  <Button
                    onClick={onCancelClick}
                    className={classes.cancel}
                    variant="outlined"
                  >
                    {t('common.cancel')}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      );

      return (
        <Box className={classes.layout}>
          <TopBar />
          {Header}

          <Box className={`${classes.wrapper} wrapper`}>
            <Box component="aside" className={classes.aside} p={3}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {availableSteps.map((step) => (
                  <Step key={step.label}>
                    <StepLabel
                      slotProps={{
                        label: {
                          sx: {
                            color: colors.text.inverted,
                            fontSize: '14px',
                            '&.Mui-active': {
                              fontWeight: 600,
                              color: colors.text.inverted,
                            },
                            '&.Mui-completed': {
                              color: colors.text.inverted,
                            },
                          },
                        },
                        stepIcon: {
                          sx: {
                            border: '1px solid',
                            borderColor: colors.text.inverted,
                            paddingRight: 0,
                            marginRight: 12,
                            borderRadius: 20,
                            '&.Mui-active': {
                              borderColor: colors.text.inverted,
                            },
                          },
                        },
                      }}
                    >
                      {renderLabel(step)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box className={classes.contentContainer} component="main">
              {!hideTimer && <ExpiryTimer onExpire={navigateToMain} />}

              <Box className={classes.content} m={3}>
                <FormHeader title={t('orders.addOrder')} hideSubmit />
                <Box p={3}>
                  <Typography variant="h2">{title}</Typography>
                  {children}
                </Box>
              </Box>
            </Box>
          </Box>

          <CommonModal
            open={cancelModalOpen}
            type="prompt"
            onConfirm={onCancelConfirm}
            onCancel={onCancelClose}
            title={t('orders.add.cancelModal.title')}
            confirmText={t('orders.add.cancelModal.confirm')}
            cancelText={t('orders.add.cancelModal.cancel')}
          >
            <>{t('orders.add.cancelModal.body')}</>
          </CommonModal>
          <CartModal isOpen={cartModalOpen} onClose={onCartModalClose} />
        </Box>
      );
    },
  );
