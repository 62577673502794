import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_BASE_URL, CUSTOMER_ID } from '../constants/siteSettings';
import { getAuthToken, setAuthToken } from '../utils/auth';
import AuthService from './AuthService';
import standardErrorHandler from './errorHandler';

/**
 * Helper for get'n  path URLs
 */
export const getPath = (
  path: string,
  itemId?: number | string,
  secondaryItemId?: number | string,
) => {
  return path
    .replace('{customerId}', CUSTOMER_ID!)
    .replace('{itemId}', String(itemId))
    .replace('{secondaryItemId}', String(secondaryItemId));
};

export const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
});

const REFRESH_TOKEN_URL = '/auth/admin/refresh';
const LOGIN_URL = '/auth/admin/login';
const LOGOUT_URL = '/auth/admin/logout';

let refreshPromise: Promise<any> | null = null;

const handleRefreshFailure = () => {
  localStorage.clear();
  window.location.replace(window.origin);
};

const errorHandler = async (error: AxiosError) => {
  const originalRequest: AxiosRequestConfig = error.config || {};

  // Login failure, do not check for anything else, route to regular error handler
  if (originalRequest.url === LOGIN_URL) {
    return standardErrorHandler(error);
  }

  // Auth token refresh failure, ignore errors and logout user
  if (
    originalRequest.url === REFRESH_TOKEN_URL ||
    originalRequest.url === LOGOUT_URL
  ) {
    handleRefreshFailure();
    return;
  }

  if (error.response && error.response.status === 401) {
    if (!refreshPromise) {
      refreshPromise = AuthService.refreshAuthToken()
        .then((response) => {
          setAuthToken(response.data.authToken);
          refreshPromise = null;
          return response.data.authToken;
        })
        .catch(async (refreshError) => {
          handleRefreshFailure();
          refreshPromise = null;
          return Promise.reject(refreshError);
        });
    }

    // Wait until the refreshPromise is resolved and retry the request
    return refreshPromise?.then((newToken) => {
      // Update the original request with the new token
      originalRequest.headers = originalRequest.headers || {};
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return api(originalRequest);
    });
  }

  // Go to standard error handler
  return standardErrorHandler(error);
};

// Error interceptor
api.interceptors.response.use((response) => response, errorHandler);

// Request interceptor
api.interceptors.request.use((config) => {
  const authToken = getAuthToken();
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (!config.responseType) {
    config.responseType = 'json';
  }

  return { ...config };
});
