import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PATHS } from '../../../constants/routes';
import { useStore } from '../../../stores/context/useStores';
import { IEventSeries } from '../../../types/Event';
import { getPathWithId } from '../../../utils/navigationUtils';
import { Tabs } from '../../Tabs';
import { Layout } from '../Layout';

export interface SkyboxEventListLayoutProps {
  title?: string;
  hideTabs?: boolean;
  headerElementsTop?: any;
}

export const SkyboxEventListLayout: FC<
  React.PropsWithChildren<SkyboxEventListLayoutProps>
> = observer(({ title, headerElementsTop, children }) => {
  const {
    getSingleEventSeries,
    getEventSeries,
    singleEventSeries,
    eventSeries,
    isLoading,
  } = useStore('eventStore');
  const { id } = useParams() as { id: string };
  const [selectedSeries, setSelectedSeries] = useState<IEventSeries>();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      id &&
      (!singleEventSeries || String(singleEventSeries.id) !== id) &&
      !isLoading
    )
      getSingleEventSeries(id);
    if (!eventSeries && !isLoading) {
      getEventSeries();
    } else if (eventSeries && !selectedSeries) {
      setSelectedSeries(
        eventSeries.find((es: IEventSeries) => String(es.id) === id),
      );
    }
  }, [
    getEventSeries,
    singleEventSeries,
    getSingleEventSeries,
    eventSeries,
    setSelectedSeries,
    selectedSeries,
    id,
    isLoading,
  ]);

  const TABS = [
    {
      label: t('common.events'),
      path: getPathWithId(PATHS.skyboxEvents.eventList, id),
    },
    {
      label: t('events.eventSeriesSettings'),
      path: getPathWithId(PATHS.skyboxEvents.eventSeriesSettings, id),
    },
  ];
  const renderTabs = () => <Tabs items={TABS} />;

  return (
    <Layout
      headerText={title || selectedSeries?.title || ''}
      headerElementsBottom={renderTabs()}
      headerElementsTop={headerElementsTop}
    >
      <Box mt={3} />
      {children}
    </Layout>
  );
});
