import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Routes as ReactRouterRoutes, Route } from 'react-router';
import { PATHS } from './constants/routes';
import LoadingScreen from './screens/Loading/LoadingScreen';
import { useStores } from './stores/context/useStores';

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(ReactRouterRoutes);

const Routes = observer(() => {
  const {
    authStore: {
      isInitialized,
      currentUser,
      currentUserRoles,
      isAuthLoading,
      refreshIsAuthenticated,
      isOwner,
      isJanitor,
      isLoggedIn,
    },
    commonStore: { isAuthenticated, currentRoutes, updateCurrentRoutes },
    customerStore: { getCurrentCustomer },
  } = useStores();

  // On initial load try to refresh user and customer data
  useEffect(() => {
    refreshIsAuthenticated(isAuthenticated || false, {
      onSuccess: getCurrentCustomer,
    });
  }, []);

  // Update accessible routes when
  // - Current user is fully initialized on page reload
  // - User roles change when user logs in or out
  useEffect(() => {
    if (isInitialized) {
      updateCurrentRoutes(currentUser, currentUserRoles);
    }
  }, [isInitialized, updateCurrentRoutes, currentUser, currentUserRoles]);

  const defaultRoute = useMemo(() => {
    if (!isLoggedIn) {
      return PATHS.auth.login;
    }

    if (isOwner) {
      return PATHS.skyboxEvents.allEvents;
    }

    if (isJanitor) {
      return PATHS.calendar.main;
    }

    return PATHS.skyboxEvents.eventSeriesList;
  }, [isLoggedIn, isJanitor, isOwner]);

  if (currentRoutes.length === 0 || isAuthLoading) {
    return <LoadingScreen />;
  }

  return (
    <SentryRoutes>
      {currentRoutes.map((route) => {
        const Screen = route.component;
        return (
          <Route
            key={route.href}
            path={route.href}
            element={
              <Suspense fallback={<LoadingScreen />}>
                <Screen />
              </Suspense>
            }
          />
        );
      })}
      <Route path="*" element={<Navigate to={defaultRoute} replace />} />
    </SentryRoutes>
  );
});

export default Routes;
