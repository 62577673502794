/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import {
  AddSkyboxToCartDTO,
  ClaimShoppingCartForUserDTO,
  ICart,
  ReserveTicketsForSkyboxDTO,
} from '../types/Order';
import { PaymentMethods } from '../types/Payment';
import { api, getPath } from './api';

const API_PATHS = Object.freeze({
  addPersonalization: '/customer/{customerId}/ticket/addPersonalization',
  addToCart: '/customer/{customerId}/skybox/addToCart',
  claimShoppingCartForUser:
    '/customer/{customerId}/purchase/claimShoppingCartForUser',
  clearCart: '/customer/{customerId}/purchase',
  createCart: '/customer/{customerId}/purchase',
  orderComment: '/customer/{customerId}/purchase/addComment',
  orderPersonalization: '/customer/{customerId}/purchase/addPersonalization',
  pay: '/customer/{customerId}/purchase/pay',
  reserveSkyboxTickets:
    '/customer/{customerId}/skybox-ticket/{itemId}/{secondaryItemId}/reserve',
  getCartById: '/customer/{customerId}/purchase/cart/{itemId}',
  payByInvoice: '/customer/{customerId}/purchase/invoice',
});

interface CreateCartDTO {
  parentId?: string;
  eventId: number | string;
}

interface MakePaymentDTO {
  cartId: string;
  redirectUrl: string;
  failRedirectUrl: string;
  contactDetails: any;
}

interface PayByInvoiceDTO extends MakePaymentDTO {
  reference?: string;
}

class OrderServiceModel {
  addSkyboxToCart = async (data: AddSkyboxToCartDTO) =>
    api.post(getPath(API_PATHS.addToCart), data);

  clearCart = async (data: { cartId: string }) =>
    api.delete(getPath(API_PATHS.clearCart), { data });

  reserveSkyboxTickets = async (data: ReserveTicketsForSkyboxDTO) =>
    api.post(
      getPath(API_PATHS.reserveSkyboxTickets, data.skyboxId, data.eventId),
      data,
    );

  createCart = async (data: CreateCartDTO) =>
    api.post(getPath(API_PATHS.createCart), data);

  getCartById = (id: string) =>
    api.get<ICart>(getPath(API_PATHS.getCartById, id));

  claimShoppingCartForUser = async (data: ClaimShoppingCartForUserDTO) =>
    api.post(getPath(API_PATHS.claimShoppingCartForUser), data);

  initPayment = async (data: MakePaymentDTO) =>
    api.post<PaymentMethods>(getPath(API_PATHS.pay), {
      cartId: data.cartId,
      redirectUrl: data.redirectUrl,
      failRedirectUrl: data.failRedirectUrl,
      ...data.contactDetails,
      version: 'v2',
    });

  addOrderPersonalization = (formData: any) =>
    api.post(getPath(API_PATHS.orderPersonalization), formData);

  addTicketPersonalization = (formData: any) =>
    api.post(getPath(API_PATHS.addPersonalization), formData);

  addOrderComment = (formData: any) =>
    api.post(getPath(API_PATHS.orderComment), formData);

  payByInvoice = async (data: PayByInvoiceDTO) =>
    api.post(getPath(API_PATHS.payByInvoice), {
      cartId: data.cartId,
      userReference: data.reference,
      redirectUrl: data.redirectUrl,
      failRedirectUrl: data.failRedirectUrl,
      ...data.contactDetails,
    });
}

const OrderService = new OrderServiceModel();

export default OrderService;
