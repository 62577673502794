export const LANGUAGES = [
  { id: 'fi', label: 'common.languages.finnish' },
  { id: 'sv', label: 'common.languages.swedish' },
  { id: 'en', label: 'common.languages.english' },
];

export const MINIMUM_PASSWORD_LENGTH = 10;

export const DEFAULT_LANGUAGE = LANGUAGES[0]!.id;

export const DEFAULT_INVOICING_FEE_VAT = 25.5;

export const API_BASE_URL = '/api';

export const ENV = import.meta.env.VITE_ENVIRONMENT;

export const SENTRY_ENABLED = ENV !== 'development';

export const resolveCustomerId = () => {
  if (ENV === 'development') {
    return import.meta.env.VITE_DEVELOPMENT_CUSTOMER_ID as string;
  }

  const host = window.location.host;
  const serviceName = host.split('.')[0];
  const slugMatch = serviceName!.match(/^(.*)-aitiot$/);

  if (slugMatch && slugMatch[1]) {
    return slugMatch[1];
  } else {
    throw Error('Could not parse customer slug');
  }
};

export const CUSTOMER_ID = resolveCustomerId();
