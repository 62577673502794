import {
  Box,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Sentry from '@sentry/react';
import fiDateFnsLocale from 'date-fns/locale/fi';
import countries from 'i18n-iso-countries';
import enJSON from 'i18n-iso-countries/langs/en.json';
import fiJSON from 'i18n-iso-countries/langs/fi.json';
import { observer } from 'mobx-react-lite';
// Import moment finnish locale for it to be available
import 'moment/dist/locale/fi';
import { useMemo } from 'react';
import { BrowserRouter } from 'react-router';
import 'typeface-inter';
import './app.css';
import { ToastProvider } from './components/Toast';
import { ENV, SENTRY_ENABLED } from './constants';
import { initLang } from './i18n';
import Routes from './Routes';
import { useStores } from './stores/context/useStores';
import { theme, updateTheme } from './styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

initLang('fi');

countries.registerLocale(enJSON);
countries.registerLocale(fiJSON);

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://63699bf18d826d4ac954c7587c00fcca@o4506353584832512.ingest.sentry.io/4506388548812800',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    environment: ENV,
  });
}

const App = observer(() => {
  const {
    customerStore: { currentCustomer },
  } = useStores();
  const customerTheme = useMemo(() => {
    if (currentCustomer) {
      return updateTheme(currentCustomer?.settings?.theme?.primary);
    }
    return theme;
  }, [currentCustomer]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customerTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <LocalizationProvider
            adapterLocale={fiDateFnsLocale.fi}
            dateAdapter={AdapterDateFns}
          >
            <CssBaseline />
            <Box className="App">
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Box>
            <ToastProvider />
          </LocalizationProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default App;
