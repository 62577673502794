/* eslint-disable react/prop-types */
import { ChevronLeft, ChevronRight, MoreHoriz } from '@mui/icons-material';
import { Box, BoxProps, Button, ButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Select } from 'mui-rff';
import React, { FC, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';

const ButtonBase = {
  height: '32px',
  width: '32px',
  padding: 0,
  fontSize: '12px',
  minWidth: 0,
  '& svg': {
    height: 18,
  },
  color: colors.text.body2,
};

const useStyles = makeStyles((theme) => ({
  paginationButton: ButtonBase,
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&  button:not(:last-child)': {
      marginRight: 8,
    },
  },
  activeButton: {
    ...ButtonBase,
    ...{
      color: colors.text.inverted,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  dots: {
    color: colors.text.body2,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageSize: {
    height: 32,
  },
}));

interface PaginationProps extends BoxProps {
  gotoPage: (pageIndex: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  totalCount: number;
  pageSize: number;
  page: number;
}

interface PaginationButtonProps extends ButtonProps {
  active?: boolean;
}

const PaginationButton: React.FC<PaginationButtonProps> = ({
  children,
  active,
  ...rest
}) => {
  const classes = useStyles();
  const { activeButton, paginationButton } = classes;
  return (
    <Button
      variant="outlined"
      className={active ? activeButton : paginationButton}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Pagination: FC<PaginationProps> = ({
  gotoPage,
  totalCount,
  pageSize,
  onPageSizeChange,
  page,
}) => {
  const totalPageCount = useMemo(
    () => Math.ceil(totalCount / pageSize),
    [totalCount, pageSize],
  );
  const classes = useStyles();
  const { t } = useTranslation();

  const onNextClick = () => {
    gotoPage(page + 1);
  };

  const onPreviousClick = () => {
    gotoPage(page - 1);
  };

  const onPageClick = (pageIndex: number) => {
    gotoPage(pageIndex);
  };

  const pages = useMemo(() => {
    const arr = [];
    for (let i = 0; i < totalPageCount; i += 1) {
      arr.push(i);
    }
    return arr;
  }, [totalPageCount]);

  const visibleIndexes = useMemo(
    () => [0, 1, pages.length - 1, pages.length - 2, page, page + 1, page - 1],
    [pages, page],
  );

  const filterItems = useMemo(() => pages.length > 10, [pages]);

  const pageSizeOpts = [10, 20, 50, 100].map((val) => ({
    value: val,
    label: String(val),
  }));

  const renderPaginationButton = (pageIndex: number) => {
    let active = page === pageIndex;
    if (pageIndex + 1 > totalPageCount && pageIndex === 0) {
      active = true;
    }

    return (
      <PaginationButton
        key={pageIndex}
        onClick={() => onPageClick(pageIndex)}
        active={active}
      >
        {pageIndex + 1}
      </PaginationButton>
    );
  };

  const renderDots = () => (
    <Box
      height={30}
      display="flex"
      marginRight="8px"
      alignItems="flex-end"
      justifyContent="center"
      width={30}
    >
      <MoreHoriz className={classes.dots} />
    </Box>
  );

  const onFormChange = ({ values }: { values: { pageSize: number } }) => {
    if (onPageSizeChange) {
      onPageSizeChange(values.pageSize);
    }
  };

  return (
    <Box className={classes.container}>
      {onPageSizeChange && (
        <Form
          onSubmit={() => undefined}
          initialValues={{ pageSize }}
          render={() => (
            <Box component="form" mt={1}>
              <Select
                label={t('common.show')}
                data={pageSizeOpts}
                name="pageSize"
                className={classes.pageSize}
              />
              <FormSpy
                subscription={{ values: true }}
                onChange={onFormChange}
              />
            </Box>
          )}
        />
      )}
      {totalPageCount >= 2 && (
        <Box className={classes.buttonContainer}>
          <PaginationButton onClick={onPreviousClick} disabled={page === 0}>
            <ChevronLeft />
          </PaginationButton>
          {!filterItems &&
            pages.map((pageIndex) => renderPaginationButton(pageIndex))}
          {filterItems &&
            pages.map((pageIndex) => {
              const isVisible = visibleIndexes.includes(pageIndex);
              if (!isVisible && pageIndex === 2) {
                return renderDots();
              }
              if (isVisible) {
                return renderPaginationButton(pageIndex);
              }
              if (!isVisible && pageIndex === page + 2) {
                return renderDots();
              }
              return null;
            })}
          <PaginationButton
            onClick={onNextClick}
            disabled={page === totalPageCount - 1}
          >
            <ChevronRight />
          </PaginationButton>
        </Box>
      )}
    </Box>
  );
};

export default Pagination;
