import { Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';
import { IToast } from '../../types/Common';

interface Props extends IToast {
  index: number;
  onClose: (id: string) => void;
}

const useStyles = makeStyles({
  alert: {
    marginBottom: theme.spacing(2),
    zIndex: 1400,
  },
});

const Toast: FC<Props> = ({ onClose, type, msg, values, id }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const onCloseClick = () => {
    onClose(id);
  };
  return (
    <Alert className={classes.alert} severity={type} onClose={onCloseClick}>
      <>{t(msg, values)}</>
    </Alert>
  );
};

export { Toast };
