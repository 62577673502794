import { Tabs as MaterialTabs, Tab } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

export type TabType<T = unknown> = {
  label: string;
  path?: string;
  onClick?: Function;
  state?: T;
};

interface TabsProps {
  items: TabType[];
  activeIndex?: number;
}

export const Tabs: FC<TabsProps> = ({ items, activeIndex }) => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPathIndex =
      activeIndex ?? items.findIndex((item) => item.path === location.pathname);
    if (currentPathIndex !== undefined) {
      setValue(currentPathIndex);
    }
  }, [location, items, activeIndex]);

  const onChange = (_e: any, tabIndex: number) => {
    setValue(tabIndex);
    const { onClick, path, state } = items[tabIndex]!;
    if (onClick) {
      onClick(tabIndex);
    }
    if (path) {
      navigate({
        pathname: path,
        search: new URLSearchParams(state as Record<string, string>).toString(),
      });
    }
  };
  return (
    <MaterialTabs value={value} onChange={onChange}>
      {items.map((item) => {
        return <Tab key={item.label} label={item.label} />;
      })}
    </MaterialTabs>
  );
};
