import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import OwnerService from '../services/OwnerService';
import UserService from '../services/UserService';
import { StoreState } from '../types/Common';
import { IAdminUser, IEndUser, SearchUsersDTO, UserRole } from '../types/User';
import { storeAutoSave } from '../utils/commonUtils';
import { ErrorCode } from '../utils/error';
import ToastStore from './ToastStore';

export class UserStore {
  state: StoreState = 'Idle';
  users: IAdminUser[] | undefined = undefined;
  user: IAdminUser | undefined = undefined;
  queriedUsers: IEndUser[] | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    const omittedFields = ['state', 'users', 'user', 'queriedUsers'];
    storeAutoSave(this, 'UserStore', omittedFields);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  setQueriedUsers = (newUsers?: IEndUser[]) => {
    this.queriedUsers = newUsers;
  };

  /**
   * Returns the user list
   */
  getUserList = async (settings: { isAdmin?: boolean }) => {
    this.state = 'Loading';
    try {
      const response = await UserService.getUserList(settings);
      runInAction(() => {
        this.users = response.data.results;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
        ToastStore.showError('errors.user.getUserList');
      });
      throw error;
    }
  };

  /**
   * Returns a user by id
   */
  getUser = async (id: string) => {
    this.state = 'Loading';
    try {
      const response = await UserService.getUser(id);
      runInAction(() => {
        this.user = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateUserPassword = async (currentPassword: string, newPassword: string) => {
    this.state = 'Loading';
    try {
      await UserService.updateUserPassword(currentPassword, newPassword);
      ToastStore.showSuccess('successes.user.updateUserPassword');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.updateUserPassword');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Create new SkyboxAdmin or SkyboxOwner user to any company (user by SkyboxAdmins)
   */
  createUser = async (user: IAdminUser, cb: Function) => {
    this.state = 'Loading';
    try {
      if (user.role === 'SkyboxAdmin') delete user.companyId;

      const { data: newUser }: { data: IAdminUser } =
        await UserService.createUser(user);

      if (user.companyId) {
        await OwnerService.addUserToSkyboxCompany(user.companyId, newUser.id);
      }
      ToastStore.showSuccess('successes.user.createSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) {
        cb(error);
      }
      ToastStore.showError(
        [ErrorCode.DUPLICATE_ENTRY, ErrorCode.USER_EXISTS].includes(
          (error as AxiosError<any>).response?.data?.code,
        )
          ? 'errors.user.createFailedBecauseExists'
          : 'errors.user.createFailed',
      );
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Create new SkyboxOwner user to own company (used by SkyboxOwners)
   */
  createOwnSkyboxCompanyUser = async (user: IAdminUser, cb: Function) => {
    this.state = 'Loading';
    try {
      await OwnerService.createOwnSkyboxCompanyUser(user);
      ToastStore.showSuccess('successes.user.createSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) {
        cb(error);
      }

      ToastStore.showError(
        [ErrorCode.DUPLICATE_ENTRY, ErrorCode.USER_EXISTS].includes(
          (error as AxiosError<any>).response?.data?.code,
        )
          ? 'errors.user.createFailedBecauseExists'
          : 'errors.user.createFailed',
      );
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Update user
   */
  updateUser = async (
    user: IAdminUser,
    cb: Function,
    settings: { isAdmin?: boolean },
  ) => {
    this.state = 'Loading';
    if (user.role === 'SkyboxAdmin') delete user.companyId;

    try {
      if (settings.isAdmin && user.companyId) {
        await OwnerService.addUserToSkyboxCompany(user.companyId!, user.id);
      }
      await UserService.updateUser(user);
      ToastStore.showSuccess('successes.user.updateSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) cb(error);
      ToastStore.showError('errors.user.updateFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  deactivateUser = async (id: string) => {
    this.state = 'Loading';
    try {
      await UserService.changeUserActiveStatus(id, { active: false });
      ToastStore.showSuccess('successes.user.deleteSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.user.deleteFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  searchUsers = async (data: SearchUsersDTO) => {
    this.state = 'Loading';
    try {
      const response = await UserService.searchUsers(data);

      runInAction(() => {
        this.state = 'Success';
        this.queriedUsers = response.data;
      });
    } catch (error) {
      ToastStore.showError('errors.user.searchUserFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  updateSuperOwnerSettings = async (
    skyboxCompanyId: string,
    role: UserRole,
    currentUser: IAdminUser,
    actions: { onSuccess: (role: UserRole) => void },
  ) => {
    if (!currentUser) {
      return;
    }
    this.state = 'Loading';

    try {
      await OwnerService.addUserToSkyboxCompany(
        skyboxCompanyId,
        currentUser.id,
      );

      runInAction(() => {
        ToastStore.showSuccess('successes.user.updateSuperOwnerSettings');
        this.state = 'Success';
      });

      actions.onSuccess(role);
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 1000);
    } catch (error) {
      ToastStore.showError('errors.user.updateSuperOwnerSettings');
    }
  };
}
