import { TableCell, TableHead, TableRow } from '@mui/material';
import { v4 } from 'uuid';
import SortToggle from './MaterialTableSortToggle';

interface SortableTableHeadProps {
  headerGroups: any[];
  onSortingChange?: (sortBy: any) => void;
  onFilteringChange?: (filters: any) => void;
}

const SortableTableHead = ({
  headerGroups,
  onSortingChange,
  onFilteringChange,
}: SortableTableHeadProps) => {
  return (
    <TableHead>
      {headerGroups.map((headerGroup) => {
        const { key, ...props } = headerGroup.getHeaderGroupProps();

        return (
          <TableRow key={headerGroup.id || v4()} {...props}>
            {headerGroup.headers.map((column: any) => {
              const params =
                onSortingChange && !column.disableSorting
                  ? column.getSortByToggleProps()
                  : undefined;
              const style = column.columnMaxWidth
                ? { width: column.columnMaxWidth }
                : undefined;
              const { key, ...props } = column.getHeaderProps(params);
              return (
                <TableCell
                  key={`header-cell-${column.id}`}
                  {...props}
                  style={style}
                >
                  <SortToggle
                    column={column}
                    isSortingEnabled={
                      !!(onSortingChange && !column.disableSorting)
                    }
                  >
                    {column.render('Header')}
                  </SortToggle>
                  <div>
                    {onFilteringChange ? column.render('Filter') : null}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
};

export default SortableTableHead;
