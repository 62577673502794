/* eslint-disable no-nested-ternary */
import ArrowDown from '@mui/icons-material/ArrowDownward';
import ArrowUp from '@mui/icons-material/ArrowUpward';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

interface SortToggleProps {
  column: {
    id?: string;
    isSorted?: boolean;
    isSortedDesc?: boolean;
    render: () => any;
  };
  isSortingEnabled: boolean;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  icon: {
    height: '18px',
    lineHeight: 1,
  },
  title: ({ isSortingEnabled }: { isSortingEnabled: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: isSortingEnabled ? 'underline' : undefined,
      cursor: isSortingEnabled ? 'pointer' : undefined,
    },
  }),
});

const SortToggle = ({
  isSortingEnabled,
  column,
  children,
}: SortToggleProps) => {
  const classes = useStyles({ isSortingEnabled });

  return (
    <span className={classes.title}>
      {children}{' '}
      {column.isSorted ? (
        column.isSortedDesc ? (
          <ArrowDown className={classes.icon} />
        ) : (
          <ArrowUp className={classes.icon} />
        )
      ) : (
        ''
      )}
    </span>
  );
};

export default SortToggle;
