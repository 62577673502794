import React from 'react';
import { Stores } from './Stores';
import { StoresContext } from './StoresContext';

export const useStores = () => {
  const stores = React.useContext(StoresContext);

  if (!stores) {
    throw new Error('Stores used without initialization');
  }

  return stores;
};

export const useStore = <T extends keyof Stores>(store: T): Stores[T] => {
  const stores = React.useContext(StoresContext);

  if (!stores) {
    throw new Error('Stores used without initialization');
  }

  return stores[store];
};
