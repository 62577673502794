import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
export interface InfoTooltipProps {
  tooltipText: string;
  hideInfoIcon?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  content: {},
  icon: {
    height: 16,
    width: 16,
  },
});
export const InfoTooltip: FC<React.PropsWithChildren<InfoTooltipProps>> = ({
  children,
  tooltipText,
  hideInfoIcon,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltipText}>
      <Box className={classes.container}>
        {!hideInfoIcon && (
          <Box mr={1}>
            <InfoIcon className={classes.icon} />
          </Box>
        )}
        <Box className={classes.content} component="span">
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
};
