import { AuthStore } from '../AuthStore';
import { BulletinStore } from '../BulletinStore';
import { CalendarStore } from '../CalendarStore';
import { CartStore } from '../CartStore';
import { CommonStore } from '../CommonStore';
import { CustomerStore } from '../CustomerStore';
import { EventStore } from '../EventStore';
import { OrderStore } from '../OrderStore';
import { OwnerStore } from '../OwnerStore';
import { ReportStore } from '../ReportStore';
import { SettingsStore } from '../SettingsStore';
import { SkyboxStore } from '../SkyboxStore';
import { UserStore } from '../UserStore';

export interface Stores {
  authStore: AuthStore;
  bulletinStore: BulletinStore;
  calendarStore: CalendarStore;
  cartStore: CartStore;
  commonStore: CommonStore;
  customerStore: CustomerStore;
  eventStore: EventStore;
  orderStore: OrderStore;
  ownerStore: OwnerStore;
  reportStore: ReportStore;
  settingsStore: SettingsStore;
  skyboxStore: SkyboxStore;
  userStore: UserStore;
}

let _stores: Stores | undefined;

const getNewStores = (): Stores => {
  return {
    commonStore: new CommonStore(),
    authStore: new AuthStore(),
    skyboxStore: new SkyboxStore(),
    eventStore: new EventStore(),
    userStore: new UserStore(),
    orderStore: new OrderStore(),
    reportStore: new ReportStore(),
    customerStore: new CustomerStore(),
    cartStore: new CartStore(),
    bulletinStore: new BulletinStore(),
    calendarStore: new CalendarStore(),
    ownerStore: new OwnerStore(),
    settingsStore: new SettingsStore(),
  };
};

export const getInitializedStores = () => {
  const stores = _stores ?? getNewStores();
  return stores;
};
